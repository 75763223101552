import { render, staticRenderFns } from "./ownerPost.vue?vue&type=template&id=065c264f&scoped=true"
import script from "./ownerPost.vue?vue&type=script&lang=js"
export * from "./ownerPost.vue?vue&type=script&lang=js"
import style0 from "./ownerPost.vue?vue&type=style&index=0&id=065c264f&prod&lang=css"
import style1 from "./ownerPost.vue?vue&type=style&index=1&id=065c264f&prod&scoped=true&lang=css"
import style2 from "./ownerPost.vue?vue&type=style&index=2&id=065c264f&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "065c264f",
  null
  
)

export default component.exports