import { render, staticRenderFns } from "./blocking.vue?vue&type=template&id=7090c493&scoped=true"
import script from "./blocking.vue?vue&type=script&lang=js"
export * from "./blocking.vue?vue&type=script&lang=js"
import style0 from "./blocking.vue?vue&type=style&index=0&id=7090c493&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7090c493",
  null
  
)

export default component.exports